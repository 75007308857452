import axios from 'axios';
import { useUserStore } from '@/stores/UserStore';

//Base api client
const API = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'X-Api-Key': process.env.VUE_APP_API_KEY,
    'Access-Control-Allow-Origin': '*'
  }
});

//API request interceptor
API.interceptors.request.use(async config => {

  const userStore = useUserStore();

  //check if this is the request to set the anonymous token, if so then skip and just return the config.
  if (config.headers.AnonymousAuthentication != "true") {

    //try to get the access token from the user store
    let bearerToken = userStore.getAccessToken
    if (bearerToken) {
      //token exists, set the authorization header
      config.headers.Authorization = `Bearer ${bearerToken}`
    }
    else {
      //token doesn't exist, call anonymous login then set the authorization header with the new token
      await userStore.anonymousLogin().then(() => {
        config.headers.Authorization = `Bearer ${userStore.getAccessToken}`
      })
    }
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

export default API;