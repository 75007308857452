import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

import '/node_modules/primeflex/primeflex.css'

import PrimeVue from 'primevue/config'

import ToastService from 'primevue/toastservice'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Password from 'primevue/password'
import FileUpload from 'primevue/fileupload'

import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

createApp(App).use(router).use(createPinia())
    .use(PrimeVue)
    .use(ToastService)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Dropdown', Dropdown)
    .component('Textarea', Textarea)
    .component('Toast', Toast)
    .component('Password', Password)
    .component('FileUpload', FileUpload)
    .mount('#app')