import { createRouter, createWebHistory } from "vue-router"
import GenerateThreePartSpecDoc from './views/GenerateThreePartSpecDoc.vue'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: GenerateThreePartSpecDoc
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export { routes };

export default router;
