<template>
  <template v-if="!userStore.isLoggedIn">
    <LoginPage />
  </template>
  <template v-else>
    <GenerateThreePartSpecDoc />
  </template>
</template>

<script>
import { useUserStore } from './stores/UserStore'
import LoginPage from './views/LoginPage.vue'
import GenerateThreePartSpecDoc from './views/GenerateThreePartSpecDoc.vue'

export default {
  name: 'App',
  components: {
    LoginPage,
    GenerateThreePartSpecDoc
},
  setup() {
    const userStore = useUserStore();
    
    return {
      userStore
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>