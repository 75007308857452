import { defineStore } from "pinia";
import AccountService from "@/services/account.service"

export const useUserStore = defineStore("UserStore", {
  state: () => {
    return {
      isLoggedIn: false,
      User: {
        UserID: 0,
        Name: '',
        Email: '',
      },
      //user access token
      token: {
        anonymous_id: "",
        access_token: "", //jwt
        token_type: "",
        expires_in: "",
      }
    };
  },

  getters: {
    //pulls back the jwt from the token
    getAccessToken: (state) => {
      return state.token.access_token;
    },
  },

  actions: {
    //log user in as an anonymous user and set access token
    async anonymousLogin() {
      await AccountService.authenticateAnonymousUser().then((response) => {
        this.token = response.data;
        return this.token;
      });
    },
    async userLogin(userModel) {
      await AccountService.authenticateAdminUser(userModel).then((response) => {
        const userInfo = this.parseJwt(response.data.access_token)

        this.token = response.data
        this.User.UserID = userInfo.nameid
        this.User.Name = userInfo.name
        this.User.Email = userInfo.email
        this.isLoggedIn = true
      })
    },
    parseJwt(token){
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  },
});
