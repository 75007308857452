import apiClient from "./api";

//api resource endpoint
const RESOURCE_NAME = 'api/v1/account';

export default {
  authenticateAnonymousUser() {
    let requestHeaders = {'AnonymousAuthentication': 'true'}
    return apiClient.get(`${RESOURCE_NAME}/AnonymousAuthenticate`, { headers: requestHeaders })
  },
  authenticateAdminUser(userModel){
    let data = JSON.stringify(userModel)
    let requestHeaders = {'Content-Type': 'application/json'}
    return apiClient.post(`${RESOURCE_NAME}/AdminAuthenticate`, data, { headers: requestHeaders})
  }
}