<!-- eslint-disable -->
<template>
    <Toast />

    <h1>Spec AI Generator</h1>

    <label>Company ID: </label><br />
    <span class="p-input-icon-right">
        <i class="pi pi-spin pi-spinner" v-if="isLoadingProduct" />
        <InputText type="text" v-model="data.companyId" @input="resetTimer" v-on:change="getProductByCompanyID" />
    </span><br /><br />

    <label v-if="data.productsDropdown.length > 0">Product: </label><br />
    <Dropdown v-if="data.productsDropdown.length > 0" v-model="data.selectedProduct" :options="data.productsDropdown"
        option-label="name" placeholder="Select a Product" />

    <br v-if="data.productsDropdown.length > 0" /><br v-if="data.productsDropdown.length > 0" />

    <div class="grid">
        <div class="col-2">
            <label>Product URL: </label><br />
            <InputText type="text" v-model="data.productUrl" />
        </div>
        <div class="col-1" style="margin-top: 2rem;">
            <label>OR</label>
        </div>
        <div class="col-6">
            <label>Upload PDF File with Product Information: </label><br />
            <FileUpload mode="basic" accept=".pdf" :auto="true" customUpload @uploader="customUploader" /><br />
            <div class="grid">
                <div class="col-1">
                    <Button v-if="data.fileName" icon="pi pi-times" size="small" @click="removePdf"></Button>
                </div>
                <div class="col-11">
                    <label v-if="data.fileName">{{ data.fileName }}</label>
                </div>
            </div>
        </div>
    </div>
    <br /><br />

    <label>Include additional context or instructions here. (Optional): </label> <br />
    <Textarea v-model="data.additionalContext"></textarea> <br /><br />

    <label>AI Model: </label><br />
    <Dropdown v-model="data.selectedAIModel" :options="data.AIModelsDropdown" option-label="name"
        placeholder="Select a AI Model" /><br /><br /><br />

    <Button label="Generate Spec AI" @click="generateSpecAI" :loading="loading" />
</template>
  
<script>
/* eslint-disable */
/* eslint-disable no-debugger */
import { reactive, ref } from 'vue'
import CompanyService from '@/services/company.service'
import ProductService from '@/services/product.service'
import ThreePartSpecDocGeneratorService from '@/services/threepartspecdocgenerator.service'
import { saveAs } from 'file-saver'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/UserStore'

export default {
    name: 'chat',

    setup() {

        const userStore = useUserStore();
        const toast = useToast()

        const data = reactive({
            companyId: '',
            company: {},
            productsDropdown: ref([]),
            selectedProduct: ref(),
            productUrl: '',
            additionalContext: '',
            chatInput: '',
            typingTimer: null,
            typingDelay: 2000,
            selectedAIModel: ref(),
            AIModelsDropdown: ref([
                { name: 'GPT-3.5-Turbo', code: 'gpt-3.5-turbo' },
                { name: 'GPT-4', code: 'gpt-4' }
            ]),
            fileName: ''
        })

        let loading = ref(false)
        let isLoadingProduct = ref(false)
        let selectedFile = null
        let intervalId = null
        let stopInterval = false

        function resetTimer() {
            clearTimeout(data.typingTimer)
            data.typingTimer = setTimeout(() => {
                getProductByCompanyID()
            }, data.typingDelay)
        }

        function getProductByCompanyID() {
            if (data.companyId != '') {
                clearTimeout(data.typingTimer)
                isLoadingProduct.value = true
                CompanyService.getById(data.companyId)
                    .then((res) => {
                        data.company = res.data

                        if (data.company != {}) {
                            ProductService.getProductsByCompanyID(data.companyId)
                                .then((res) => {
                                    data.productsDropdown = res.data.value.map((product) => {
                                        return { name: product.Name, code: product.ProductID }
                                    })
                                })
                        }

                        isLoadingProduct.value = false
                    })
                    .catch((error) => {
                        console.log(error)

                        data.company = {}
                        data.productsDropdown = []
                        toast.add({ severity: 'error', summary: 'Error', detail: 'Error to get the Company`s products.', life: 3000 })

                        isLoadingProduct.value = false
                    })
            } else {
                data.company = {}
                data.productsDropdown = []
            }
        }

        function customUploader(event) {
            selectedFile = event.files[0]
            data.fileName = selectedFile.name
        }

        function removePdf() {
            selectedFile = null
            data.fileName = ''
        }

        async function generateSpecAI() {

            if (data.company == {} || data.selectedProduct == null || (data.productUrl == '' && selectedFile == null) || data.selectedAIModel == null) {
                toast.add({ severity: 'error', summary: 'Error', detail: 'Please fill all fields', life: 3000 })
            } else {
                loading.value = true

                const encodedParamUrl = encodeURIComponent(data.productUrl)
                let documentID = await ThreePartSpecDocGeneratorService.generateThreePartSpecDoc(selectedFile, userStore.User.UserID, data.companyId, data.selectedProduct.code, encodedParamUrl, data.selectedAIModel.code, data.additionalContext).then((response) => {
                    return response.data
                }).catch(() => {
                    toast.add({ severity: 'error', summary: 'Error', detail: 'Error to generate the Spec Document.', life: 3000 })
                    loading.value = false
                    return null
                })

                if (documentID != null) {
                    intervalId = setInterval(async () => {
                        await downloadGeneratedDocument(documentID)

                        if (stopInterval) {
                            clearInterval(intervalId)
                        }
                    }, 15000)
                }
            }
        }

        async function downloadGeneratedDocument(documentID) {
            await ThreePartSpecDocGeneratorService.downloadThreePartSpecDoc(documentID).then((response) => {
                saveAs(response.data, "3-PartSpec.docx")
                stopInterval = true
                loading.value = false
            }).catch(() => {
                stopInterval = false
            })
        }

        return {
            resetTimer,
            getProductByCompanyID,
            customUploader,
            removePdf,
            generateSpecAI,
            data,
            loading,
            isLoadingProduct
        }
    }
}
</script>  