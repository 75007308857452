import apiClient from "./api";

//api resource endpoint
const RESOURCE_NAME = 'odata/company';

export default {
  getById(companyID){
    return apiClient.get(`${RESOURCE_NAME}/${companyID}?&$expand=CompanyAddresses`);
  }
}
