import apiClient from "./api"

// API resource endpoint
const RESOURCE_NAME = 'api/v1/ThreePartSpecDocGenerator';

export default {
    generateThreePartSpecDoc(selectedFile, userID, companyID, productID, productUrl, aiModel, additionalContext) {
        const formData = new FormData()
        formData.append("file", selectedFile)
        formData.append("userID", userID)
        formData.append("companyID", companyID)
        formData.append("productID", productID)
        formData.append("productUrl", productUrl)
        formData.append("chatModel", aiModel)
        formData.append("additionalContext", additionalContext)
        let requestHeaders = { 'Content-Type': 'multipart/form-data' }
        return apiClient.post(`${RESOURCE_NAME}/GenerateThreePartSpecDoc`, formData, { headers: requestHeaders })
    },
    downloadThreePartSpecDoc(documentID) {
        return apiClient.get(`${RESOURCE_NAME}/GetGeneratedThreePartSpecDoc/?docID=${documentID}`, { responseType: 'blob' })
    }
}