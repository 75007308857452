<template>
    <div class="center-container">
        <Toast />
        <h1>Login</h1>

        <InputText type="text" v-model="data.email" placeholder="Email" class="input-field" /><br />
        <Password v-model="data.password" placeholder="Password" :feedback="false" toggleMask class="input-field" /><br /><br />

        <Button label="LOGIN" @click="loging" :loading="loading" />
    </div>
</template>

<script>
/* eslint-disable */
import { reactive, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/UserStore'

export default {
    name: 'chat',

    setup() {

        const userStore = useUserStore();
        const toast = useToast()
        let loading = ref(false)

        const data = reactive({
            email: '',
            password: ''
        })

        async function loging() {
            loading.value = true
            if (data.email != '' && data.password != '') {
                loading.value = true
                await userStore.userLogin({ Username: data.email, Password: data.password })
                .catch((err) => {
                    toast.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 })
                })
                loading.value = false
            } else {
                loading.value = false
                toast.add({ severity: 'error', summary: 'Error', detail: 'Please enter email and password', life: 3000 })
            }
        }

        return {
            loging,
            data,
            loading
        }
    }
}
</script>

<style scoped>
.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input-field {
  width: 15rem;
}
</style>
