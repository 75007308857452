import apiClient from "./api";

//api resource endpoint
const RESOURCE_NAME = 'odata/product';

export default { 
  getProductsByCompanyID(companyID) {
    return apiClient.get(`${RESOURCE_NAME}/?$count=true`
      + `&$filter=CompanyID eq ${companyID} and IsEnabled and ProductImages/any(y:y ne null) and ProductListings/any(z:z/Listing/IsEnabled)`
      )
  }
}